import { type SVGProps } from 'react'
import { cn } from '~/utils/misc.tsx'
import href from './sprite.svg'

import { type IconName } from '@/icon-name'
export { href }
export { IconName }

const sizeClassName = {
  xs: 'w-2.5 h-2.5',
  font: 'w-4 h-4',
  // added both w amd min-w to avoid rescaling size
  drawer: 'w-[24px] h-[24px] min-w-[24px] min-h-[24px]',
  lg: 'w-[32px] h-[32px] min-w-[32px] min-h-[32px]',
} as const

type Size = keyof typeof sizeClassName

const childrenSizeClassName = {
  xs: 'gap-1',
  font: 'gap-1.5',
  drawer: 'gap-3',
  lg: '',
} satisfies Record<Size, string>

export type IconProps = SVGProps<SVGSVGElement> & {
  name: IconName
  size?: Size
  containerClassname?: string
}
/**
 * Renders an SVG icon. The icon defaults to the size of the font. To make it
 * align vertically with neighboring text, you can pass the text as a child of
 * the icon and it will be automatically aligned.
 * Alternatively, if you're not ok with the icon being to the left of the text,
 * you need to wrap the icon and text in a common parent and set the parent to
 * display "flex" (or "inline-flex") with "items-center" and a reasonable gap.
 */
export function Icon({
  name,
  size = 'font',
  className,
  children,
  containerClassname,
  ...props
}: IconProps) {
  if (children) {
    return (
      <span
        className={cn(
          `inline-flex items-center ${childrenSizeClassName[size]}`,
          containerClassname,
        )}
      >
        <Icon name={name} size={size} className={className} {...props} />
        {children}
      </span>
    )
  }
  return (
    <svg
      {...props}
      className={cn(sizeClassName[size], 'inline self-center', className)}
    >
      <use href={`${href}#${name}`} />
    </svg>
  )
}
